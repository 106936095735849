import { Component, Vue } from 'vue-property-decorator';
import { Getter, State as ClassState, Action } from 'vuex-class';
import Loader from '@/components/common/loader/Loader.vue';
import { formatNumber } from '@/filters/number';
import { Asset } from '@/store/models/asset';
import { Payment, PaymentStatus } from '@/store/models/investment';
import { PaymentMethod } from '@/helpers/mollie';
import { State } from '@/store/models';

@Component({
  components: {
    Loader,
  },
})

export default class CheckoutStatus extends Vue {
  formatNumber = formatNumber;
  PaymentStatus = PaymentStatus;
  analyticsSent = false;

  @Action callMetaAPI!: ({ eventName, extraInfo }: { eventName: string, extraInfo?: { [key: string]: unknown } }) => void;

  @Getter getLengthPaidInvestments!: number;
  @Getter getPaymentById!: Function;

  @ClassState user!: State['user'];

  mounted(): void {
    this.$watch(
      // Watching all those data inputs so we can do the same functionality in one same place
      // @ts-ignore
      (vm): any => [vm.paymentStatus, vm.payment, vm.asset].join(),
      (): void => {
        if (this.paymentStatus === PaymentStatus.Paid && this.payment && this.asset?.id && this.$gtm?.enabled() && !this.analyticsSent) {
          const productCost = this.payment.providerData.metadata.euroAmount / this.sharesBought;
          this.$gtm.trackEvent({
            event: 'purchase',
            ecommerce: {
              currency: 'EUR',
              transaction_id: this.payment.providerData.metadata.paymentId,
              affiliation: 'Bloqify',
              value: this.payment.providerData.metadata.euroAmount,
              items: [{
                item_name: this.asset?.name.en,
                item_id: this.asset?.id,
                price: productCost,
                quantity: this.sharesBought,
                item_category: this.payment.type,
              }],
              existing_customer: this.getLengthPaidInvestments > 0,
            },
          });
          const extraInfo = { custom_data: { currency: 'EUR', value: this.payment.providerData.metadata.euroAmount } };
          this.callMetaAPI({ eventName: 'Purchase', extraInfo });

          if (this.payment.type === 'subscription') {
            this.callMetaAPI({ eventName: 'Subscribe', extraInfo });
          }

          this.analyticsSent = true;
        }
      },
    );
  }

  get payment(): Payment | undefined {
    return this.getPaymentById(this.$route.params.paymentId);
  }

  get asset(): Asset {
    return this.payment?.asset as Asset;
  }

  get sharesBought(): number {
    return Number(this.payment?.providerData.metadata!.sharesAmount);
  }

  get euroBought(): number {
    return this.payment?.providerData.metadata.euroAmount || 0;
  }

  get paymentStatus(): PaymentStatus | undefined {
    return this.payment?.providerData.status;
  }

  get paymentMethod(): PaymentMethod.banktransfer | 'other' {
    return this.payment?.providerData.method === PaymentMethod.banktransfer ? PaymentMethod.banktransfer : 'other';
  }

  get paymentLink(): string {
    const mollieLink = this.payment?.providerData._links?.checkout?.href;
    if (mollieLink) return `${mollieLink}`;
      return ''; // case when the payment is already paid, but this link should not be used then anyway
  }

  // Opening the support box from AccountDashboard
  handleSupport(): void {
    this.$emit('handle-support-event');
  }
}
