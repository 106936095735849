// eslint-disable-next-line import/extensions,import/no-unresolved
import type { PaymentMethod as PM } from '@mollie/api-client/dist/types/src/data/global';
// eslint-disable-next-line import/extensions,import/no-unresolved
import type { PaymentData, PaymentStatus as PS } from '@mollie/api-client/dist/types/src/data/payments/data';

export { PaymentData };

export enum PaymentMethod {
  applepay = 'applepay',
  bancontact = 'bancontact',
  sofort = 'sofort',
  banktransfer = 'banktransfer',
  belfius = 'belfius',
  creditcard = 'creditcard',
  directdebit = 'directdebit',
  eps = 'eps',
  giftcard = 'giftcard',
  giropay = 'giropay',
  ideal = 'ideal',
  kbc = 'kbc',
  klarnapaylater = 'klarnapaylater',
  klarnasliceit = 'klarnasliceit',
  paypal = 'paypal',
  paysafecard = 'paysafecard',
  przelewy24 = 'przelewy24',
}

export enum PaymentStatus {
  Open = 'open',
  Cancel = 'cancel',
  Pending = 'pending',
  Authorized = 'authorized',
  Expired = 'expired',
  Failed = 'failed',
  Paid = 'paid',
}
